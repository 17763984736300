<template>
  <v-container>
    <v-row>

      <!-- Selezione dei componenti -->
      <v-col cols="12" class="mb-3">
        <v-btn
          v-for="(component, index) in components"
          :key="index"
          @click="selectComponent(component)"
          class="mr-2"
          outlined
        >
          {{ component }}
        </v-btn>
      </v-col>

      <!-- Selezione dei dataset -->
      <v-col cols="12" v-if="datasets">
        <h3>Seleziona un dataset:</h3>
        <v-btn
          v-for="(dataset, index) in datasets"
          :key="index"
          @click="loadDataset(dataset)"
          class="mr-2"
          outlined
          color="primary"
        >
          {{ dataset }}
        </v-btn>
      </v-col>

      <!-- Visualizzazione del componente selezionato -->
      <v-col cols="12" v-if="currentComponent">
        <component :is="currentComponent" v-bind="dynamicProps" />
      </v-col>

      <v-col cols="12" v-else>
        <p>Seleziona un componente e un dataset per iniziare.</p>
      </v-col>
    </v-row>

    {{ dynamicProps }}
    {{ currentData }}
  </v-container>
</template>

<script>

import axios from "axios";
import ResponseATRCDetailTable from "./verti/ania/ResponseATRCDetailTable.vue";
export default {
  data() {
    return {
      activeComponentName:"",
      components: {
        ResponseATRCDetailTable
      }, // Mappa dei componenti importati manualmente
      datasets: [], // Lista dei dataset
      currentComponent: null, // Componente Vue dinamico
      currentData: [], // Dataset caricato
      dynamicProps: {}, // Proprietà dinamiche da passare al componente
      config:{}
    };
  },
  created() {
    this.fetchComponents();
  },
  methods: {
    /**
     * Ottieni il valore annidato da un oggetto dato un percorso in dot notation.
     */
    getNestedValue(obj, path) {
      console.log(obj,path)
      return path.split(".").reduce((o, key) => (o ? o[key] : undefined), obj);
    },
    /**
     * Carica l'elenco dei componenti disponibili dal server.
     */
    async fetchComponents() {
      try {
        const response = await axios.get("http://localhost:3000/components");
        this.components = response.data;
      } catch (error) {
        console.error("Errore nel caricamento dei componenti:", error);
      }
    },
    /**
     * Carica i dataset e la configurazione del componente selezionato.
     */
    async selectComponent(componentKey) {
      try {
        // Carica i dataset
        console.log("seleziono",componentKey)
        this.activeComponentName = componentKey
        const datasetResponse = await axios.get(
          `http://localhost:3000/components/${componentKey}/datasets`
        );
        this.datasets = datasetResponse.data;

        // Carica il file di configurazione
        const configResponse = await axios.get(
          `http://localhost:3000/components/${componentKey}/config`
        );

        this.config = configResponse.data;
        
        this.currentComponent = this.components[componentKey];

      // this.currentComponent = defineAsyncComponent(() => {
      //       console.log("Importando componente da:", this.config.componentPath);
      //       return import(/* @vite-ignore */ this.config.componentPath)
      //       .catch((err) => {
      //           console.error("Errore nell'import dinamico:", err);
      //           throw err;
      //       });
      // });

      // console.log("config",this.config,this.config.mappings);
      
      
      } catch (error) {
        console.error("Errore nella selezione del componente:", error);
      }
    },
    /**
     * Carica un dataset specifico per il componente selezionato.
     */
    async loadDataset(dataset) {
                // Resetta il dataset corrente
                this.currentData = [];
        this.dynamicProps = {};
      try {
        const response = await axios.get(
          `http://localhost:3000/components/${this.activeComponentName}/datasets/${dataset}`
        );
        this.currentData = response.data;



        // Mappa i percorsi e aggiorna dynamicProps
        if (this.config.mappings) {
          this.config.mappings.forEach((mapping) => {
            const datasetValue = this.getNestedValue(this.currentData, mapping.datasetPath);
            console.log("mapping",datasetValue);
            this.$set(this.dynamicProps, mapping.componentProp, datasetValue);
          });
        }

        // Aggiorna dynamicProps in base ai dati caricati
        this.updateDynamicProps();
      } catch (error) {
        console.error("Errore nel caricamento del dataset:", error);
      }
    },
    /**
     * Aggiorna le proprietà dinamiche del componente in base al dataset corrente.
     */
    updateDynamicProps() {
      if (this.currentComponentConfig?.mappings) {
        this.currentComponentConfig.mappings.forEach((mapping) => {
          const datasetValue = this.getNestedValue(this.currentData, mapping.datasetPath);
          this.$set(this.dynamicProps, mapping.componentProp, datasetValue);
        });
      }
    },
  },
};
</script>

<style scoped>
/* Stile per pulsanti e layout con Vuetify */
button {
  margin: 5px;
  padding: 10px 15px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
}
button:hover {
  background-color: #d0d0d0;
}
</style>
