export default {
    "Se il contraente della polizza \u00e8 una ditta individuale, scegliere il sesso del professionista.": "Se il contraente della polizza \u00e8 una ditta individuale, scegliere il sesso del professionista.",
    "Male": "Male",
    "Female": "Female",
    "Juridic": "Juridic"
};

export const GenderDto = {
    "Se il contraente della polizza \u00e8 una ditta individuale, scegliere il sesso del professionista.": {
        "label": "Enum description",
        "description": "Enum description"
    },
    "Male": {
        "label": "Maschio",
        "description": "Maschio"
    },
    "Female": {
        "label": "Femmina",
        "description": "Femmina"
    },
    "Juridic": {
        "label": "Giuridica",
        "description": "Persona giuridica"
    }
};