<template>
    <div>
      <section id="AGGIORNAMENTO" class="mb-5">
        <v-row>
          <v-col cols="12">
            <h3 class="h6 text-uppercase text-left primary--text">
              Ultimo aggiornamento: {{formatDate(ResponseAtrcMain.updatesReceptionDate)}}
              
            </h3>
          </v-col>
        </v-row>
       

   
      </section>


      <!-- <section id="COMPAGNIA" class="mb-5">
        <v-row>
          <v-col cols="12">
            <h3 class="h6 text-uppercase text-center primary--text">COMPAGNIA</h3>
          </v-col>
        </v-row>
        <v-row>

          <v-col style="border-right:1px solid lightgray">
            <table class="w-100 table">
              <tr>
                <td class="w-50 text-right pr-2">Cognome/ragione sociale:</td>
                <td class="w-50 text-left font-weight-bold black--text">{{ ResponseAtrcMain.beneficiaryLastName }}</td>
              </tr>
              <tr>
                <td class="w-50 text-right pr-2">Scadenza contrattuale:</td>
                <td class="w-50 text-left font-weight-bold black--text">{{ formatDate(ResponseAtrcMain.contractExpirationDate) }}</td>
              </tr>
            </table>
          </v-col>


          <v-col style="border-right:1px solid lightgray">
            <table class="w-100 table">
              <tr>
                <td class="w-50 text-right pr-2">Nome:</td>
                <td class="w-50 text-left font-weight-bold black--text">{{ ResponseAtrcMain.beneficiaryFirstName }}</td>
              </tr>
              <tr>
                <td class="w-50 text-right pr-2">Forma tariffaria</td>
                <td class="w-50 text-left font-weight-bold black--text" >{{ AtrcTariff.label(ResponseAtrcMain.tariff) }}</td>
              </tr>
            </table>
          </v-col>


          <v-col>
            <table class="w-100 table">
              <tr>
                <td class="w-50 text-right pr-2">Identificativo fiscale (sivi)</td>
                <td class="w-50 text-left font-weight-bold black--text">
                  {{ ResponseSivi.taxCode}}
                </td>
              </tr>
              <tr>
               
                <td class="w-50 text-right pr-2">Franchigia non corrisposta</td>
                <td class="w-50 text-left font-weight-bold black--text">
                 N° {{ ResponseAtrcMain.deductibleAmount }} - 
                 Importo {{ ResponseAtrcMain.deductibleAmount }}
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </section> -->



      <section id="CONTRAENTE" class="mb-5">
        <v-row>
          <v-col cols="12">
            <h3 class="h6 text-uppercase text-center primary--text">Contraente</h3>
          </v-col>
        </v-row>
        <v-row>

          <v-col style="border-right:1px solid lightgray">
            <table class="w-100 table">
              <tr>
                <td class="w-50 text-right pr-2">Cognome/ragione sociale:</td>
                <td class="w-50 text-left font-weight-bold black--text">{{ ResponseAtrcMain.policyholderLastName }}</td>
              </tr>
           
            </table>
          </v-col>


          <v-col style="border-right:1px solid lightgray">
            <table class="w-100 table">
              <tr>
                <td class="w-50 text-right pr-2">Nome:</td>
                <td class="w-50 text-left font-weight-bold black--text">{{ ResponseAtrcMain.policyholderFirstName }}</td>
              </tr>
           
            </table>
          </v-col>


          <v-col>
            <table class="w-100 table">
              <tr>
                <td class="w-50 text-right pr-2">Identificativo fiscale (atrc):</td>
                <td class="w-50 text-left font-weight-bold">
                  {{ ResponseAtrcMain.policyholderTaxCode || "---"}}
                </td>
              </tr>
            
            </table>
          </v-col>
        </v-row>
      </section>


      <section id="AVENTE_DIRITTO" class="mb-5">
        <v-row>
          <v-col cols="12">
            <h3 class="h6 text-uppercase text-center primary--text">AVENTE DIRITTO - Proprietario - Natura Fisica</h3>
          </v-col>
        </v-row>
        <v-row>

          <v-col style="border-right:1px solid lightgray">
            <table class="w-100 table">
              <tr>
                <td class="w-50 text-right pr-2">Cognome/ragione sociale:</td>
                <td class="w-50 text-left font-weight-bold black--text">{{ ResponseAtrcMain.beneficiaryLastName }}</td>
              </tr>
              <tr>
                <td class="w-50 text-right pr-2">Scadenza contrattuale:</td>
                <td class="w-50 text-left font-weight-bold black--text">{{ formatDate(ResponseAtrcMain.contractExpirationDate) }}</td>
              </tr>
            </table>
          </v-col>


          <v-col style="border-right:1px solid lightgray">
            <table class="w-100 table">
              <tr>
                <td class="w-50 text-right pr-2">Nome:</td>
                <td class="w-50 text-left font-weight-bold black--text">{{ ResponseAtrcMain.beneficiaryFirstName }}</td>
              </tr>
              <tr>
                <td class="w-50 text-right pr-2">Forma tariffaria</td>
                <td class="w-50 text-left font-weight-bold black--text" >{{ AtrcTariff.label(ResponseAtrcMain.tariff) }}</td>
              </tr>
            </table>
          </v-col>


          <v-col>
            <table class="w-100 table">
              <tr>
                <td class="w-50 text-right pr-2">Identificativo fiscale (sivi)</td>
                <td class="w-50 text-left font-weight-bold black--text">
                  {{ ResponseSivi.taxCode}}
                </td>
              </tr>
              <tr>
               
                <td class="w-50 text-right pr-2">Franchigia non corrisposta</td>
                <td class="w-50 text-left font-weight-bold black--text">
                 N° {{ ResponseAtrcMain.deductibleAmount }} - 
                 Importo {{ ResponseAtrcMain.deductibleAmount }}
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </section>

      <!-- CLASSI DI RISCHIO -->
      <section style="border-top:0px solid lightgray" class="mb-5" >
        <v-row>
          <v-col cols="12" md="6" class="border-right" style="border-right:1px solid lightgray">
            <h3 class="h6 text-uppercase text-center primary--text">CLASSE IMPRESA</h3>
            <v-row class="text-center">
              <v-col class="text--error">
                <span class="overline text-uppercase mr-2">Provenienza:</span>
                <span class="mini-card-text font-weight-bold black--text">{{ ResponseAtrcMain.originalCompanyCategory }}</span>
              </v-col>
              <v-col>
                <span class="overline text-uppercase mr-2">Assegnazione:</span>
                <span class="mini-card-text font-weight-bold black--text">{{ ResponseAtrcMain.assignedCompanyCategory }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <h3 class="h6 text-uppercase text-center primary--text">CLASSE CU</h3>
            <v-row class="text-center">
              <v-col>
                <span class="overline text-uppercase mr-2">Provenienza:</span>
                <span class="mini-card-text font-weight-bold black--text">{{ ResponseAtrcMain.originalRiskCategory }}</span>
              </v-col>
              <v-col>
                <span class="overline text-uppercase mr-2">Assegnazione:</span>
                <span class="mini-card-text font-weight-bold black--text">{{ ResponseAtrcMain.riskCategory }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </section>

      <v-simple-table>
        <thead>
          <tr>
            <th>Tipo di Sinistro</th>
            <th v-for="year in years" :key="year">{{ year }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in tableData" :key="row.claimsType">
            <td><strong>{{ AtrcClaimType.label(row.claimsType) }}</strong></td>
            <td
              v-for="(value, index) in row.values"
              :key="index"
              :data-value="getDataValueAttribute(value)"
            >
              {{ value }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    
    </div>
  </template>
  
  <script>
import { AtrcClaimTypeMixin } from '@/enum/Verti/Atrc/AtrcClaimTypeObj';
import { AtrcTariffMixin } from '@/enum/Verti/Atrc/AtrcTariffObj';
import { DateMixin } from '@/services/DateUtils';

  export default {
    name: "ResponseATRCDetailTable",
    props: {
      datiAnia: {
        type: Object,
        required: true,
      },
    },
    mixins: [DateMixin,AtrcTariffMixin,AtrcClaimTypeMixin],
    computed: {
      ResponseAtrcMain() {
        return this.datiAnia.ResponseAtrcMain[0];
      },
      ResponseAtrcDetail() {
        return this.datiAnia.ResponseAtrcMain[0].ResponseATRCDetail;
      },
      ResponseSivi() {
        return this.datiAnia.ResponseSivi[0];
      },
      years() {
        // Determina gli anni dalla prima entry di ResponseAtrcDetail
        if (this.ResponseAtrcDetail.length > 0) {
          return Object.keys(this.ResponseAtrcDetail[0])
            .filter((key) => key.match(/^a\d+Year$/))
            .map((key) => this.ResponseAtrcDetail[0][key]);
        }
        return [];
      },
      tableData() {
        return this.ResponseAtrcDetail.map((entry) => {
          const claimsType = entry.claimsType;
          const values = Object.keys(entry)
            .filter((key) => key.match(/^a\d+ClaimNumber$/))
            .map((key) => entry[key]);
  
          return { claimsType, values };
        });
      },
    },
    methods: {
      getDataValueAttribute(value) {
        if (value === "00") {
          return "00";
        } else if (value === "--") {
          return "--";
        } else if (value === "NA") {
          return "NA";
        } else if (/^0[1-9]$|^0\d$/.test(value)) {
          return "sinistro";
        } else {
          return "other";
        }
      },
      allValuesAreEqual(row, value) {
        return row.values.every((cell) => cell === value);
      },
      containsDifferentValues(row, allowedValues) {
        return row.values.some((cell) => !allowedValues.includes(cell));
      },
    },
  };
  </script>
  
  <style>
  th {
    /* background-color: rgb(220, 220, 220); */
    font-weight: bolder;
    border-top: 1px solid rgb(100, 100, 100)!important;
    border-bottom: 1px solid rgb(100, 100, 100)!important;
  }
  [data-value="00"] {
    background-color: rgb(230, 230, 230);
  }
  [data-value="--"] {
    background-color: rgb(230, 230, 230);
  }
  [data-value="NA"] {
    background-color: rgb(230, 230, 230);
  }
  [data-value="sinistro"] {
    background-color: coral;
  }
  [data-value="other"] {
    background-color: rgb(230, 230, 230);
  }
  </style>
  