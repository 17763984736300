export const VehicleCategoryEnum = [
  {
    label: "Auto",
    description: "Automobile",
    value: "Auto"
  },
  {
    label: "Moto > 50cc",
    description: "Motorcycle with more than 50cc",
    value: "MotoMore50cc"
  },
  {
    label: "Moto <= 50cc",
    description: "Motorcycle with less than 50cc",
    value: "MotoLess50cc"
  },
];
