export default {
    "PermanentResidency": "PermanentResidency",
    "OtherResidency": "OtherResidency",
    "CompanyHeadquarters": "CompanyHeadquarters",
    "CompanyOther": "CompanyOther",
    "SiviSivo": "SiviSivo"
};

export const AddressType = {
    "PermanentResidency": {
        "label": "Residenza permanente",
        "description": "Residenza permanente"
    },
    "OtherResidency": {
        "label": "Altra residenza",
        "description": "Altra residenza"
    },
    "CompanyHeadquarters": {
        "label": "Sede centrale della societ\u00e0",
        "description": "Sede centrale della societ\u00e0"
    },
    "CompanyOther": {
        "label": "Altra sede della societ\u00e0",
        "description": "Altra sede della societ\u00e0"
    },
    "SiviSivo": {
        "label": "Sivi Sivo",
        "description": "Sivi Sivo"
    }
};