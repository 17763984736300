// Generated from PHP Enum App\Service\Verti\Enums\Atrc\AtrcClaimType

import EnumBase from "@/enum/EnumBase"
export const AtrcClaimTypeObj = {
    SinistroResponsabilitaTotale: '01',
    SinistroConConcorsoDiColpa: '1C',
    SinistroConDannoMaterialeMinore: '1M',
    SinistroConDannoAPersoneParziale: '1P',
    SinistroConResponsabilitaParziale: '02',
    SinistroConConcorsoDiColpaGrave: '2C',
    SinistroConDannoAPersoneTotale: '2P',
    SinistroConDannoMaterialeGrave: '2M',
};
export class AtrcClaimType extends EnumBase {
constructor() {
            
	super()
            
	this.data = {
    "01": {
        "label": "Sinistri pagati con responsabilit\u00e0 principale",
        "description": "Sinistro Responsabilit\u00e0 Totale (100%), Indica un sinistro in cui l'assicurato \u00e8 considerato completamente responsabile per il danno (senza concorso di colpa da altre parti)."
    },
    "1C": {
        "label": "Sinistri pagati con responsabilit\u00e0 paritaria",
        "description": "Significa che l'assicurato e la controparte hanno una responsabilit\u00e0 condivisa nel causare il danno, di solito al 50%."
    },
    "1M": {
        "label": "di cui con danni a: COSE",
        "description": "Un sinistro che comporta danni materiali lievi o di modesta entit\u00e0."
    },
    "1P": {
        "label": "di cui con danni a: PERSONE",
        "description": "Il danno riguarda persone, ma con una responsabilit\u00e0 parziale."
    },
    "02": {
        "label": "di cui con danni a: MISTI (persone+cose)",
        "description": "Il sinistro in cui la responsabilit\u00e0 \u00e8 parziale, tipicamente meno del 50%."
    },
    "2C": {
        "label": "Sinistri pagati con responsabilit\u00e0 paritaria",
        "description": "Indica una responsabilit\u00e0 condivisa, ma in modo grave, forse con danni significativi da entrambe le parti."
    },
    "2P": {
        "label": "di cui con danni a: COSE",
        "description": "Un sinistro che ha causato danni a persone, e la responsabilit\u00e0 dell'assicurato \u00e8 totale."
    },
    "2M": {
        "label": "di cui con danni a: PERSONE",
        "description": "Si riferisce a un sinistro che ha causato danni materiali importanti o gravi."
    }
}
}
}
        export const AtrcClaimTypeMixin = {
            data() {
                return {
                    AtrcClaimType: new AtrcClaimType(), // Istanza della classe
                };
            },
        };
        