// Generated from PHP Enum App\Service\Verti\Enums\Atrc\AtrcTariff

import EnumBase from "@/enum/EnumBase"
export const AtrcTariffObj = {
    BONUS_MALUS: 1,
    FRANCHIGIA: 2,
    PREMIO_FISSO: 3,
    CHILOMETRAGGIO: 4,
    CONSUMO: 5,
};
export class AtrcTariff extends EnumBase {
constructor() {
            
	super()
            
	this.data = {
    "1": {
        "label": "Bonus malus",
        "description": "Bonus malus"
    },
    "2": {
        "label": "Franchigia",
        "description": "Franchigia"
    },
    "3": {
        "label": "Premio fisso",
        "description": "Premio fisso"
    },
    "4": {
        "label": "Chilometraggio",
        "description": "Chilometraggio"
    },
    "5": {
        "label": "Consumo",
        "description": "Consumo"
    }
}
}
}
        export const AtrcTariffMixin = {
            data() {
                return {
                    AtrcTariff: new AtrcTariff(), // Istanza della classe
                };
            },
        };
        