// Generated from PHP Enum App\Enums\QuotazioneStato

import EnumBase from "@/enum/EnumBase"
export const QuotazioneStatoObj = {
    STATO_BOZZA_ID: 1,
    STATO_BOZZA_COD: 1,
    STATO_IN_ATTESA_DI_DEROGA_ID: 2,
    STATO_IN_ATTESA_DI_DEROGA_COD: 2,
    STATO_QUOTAZIONE_APPROVATA_ID: 3,
    STATO_QUOTAZIONE_APPROVATA_COD: 3,
    STATO_IN_ATTESA_COMPILAZIONE_DIRETTA_ID: 12,
    STATO_IN_ATTESA_COMPILAZIONE_DIRETTA_COD: 3.5,
    STATO_IN_ATTESA_COMPILAZIONE_FORM_ID: 4,
    STATO_IN_ATTESA_COMPILAZIONE_FORM_COD: 4,
    STATO_PREVENTIVO_IN_ATTESA_DOCUMENTAZIONE_ID: 5,
    STATO_PREVENTIVO_IN_ATTESA_DOCUMENTAZIONE_COD: 5,
    STATO_PREVENTIVO_IN_ATTESA_DI_VERIFICA_ID: 6,
    STATO_PREVENTIVO_IN_ATTESA_DI_VERIFICA_COD: 6,
    STATO_IN_ATTESA_DI_EMISSIONE_ID: 7,
    STATO_IN_ATTESA_DI_EMISSIONE_COD: 7,
    STATO_RICHIESTA_EMISSIONE_ID: 8,
    STATO_RICHIESTA_EMISSIONE_COD: 8,
    STATO_IN_ATTESA_DI_FIRMA_ID: 9,
    STATO_IN_ATTESA_DI_FIRMA_COD: 9,
    STATO_CONCLUSA_CON_SUCCESSO_ID: 10,
    STATO_CONCLUSA_CON_SUCCESSO_COD: 10,
    STATO_CONCLUSA_CON_ERRORE_ID: 11,
    STATO_CONCLUSA_CON_ERRORE_COD: 11,
    STATO_BOZZA_RINNOVO_ID: 13,
    STATO_BOZZA_RINNOVO_COD: 1.5,
};
export class QuotazioneStato extends EnumBase {
constructor() {
            
	super()
            
	this.data = {
    "1": {
        "label": "Stato bozza rinnovo cod",
        "description": "Stato bozza rinnovo cod"
    },
    "2": {
        "label": "Stato in attesa di deroga cod",
        "description": "Stato in attesa di deroga cod"
    },
    "3": {
        "label": "Stato in attesa compilazione diretta cod",
        "description": "Stato in attesa compilazione diretta cod"
    },
    "12": {
        "label": "Stato in attesa compilazione diretta id",
        "description": "Stato in attesa compilazione diretta id"
    },
    "4": {
        "label": "Stato in attesa compilazione form cod",
        "description": "Stato in attesa compilazione form cod"
    },
    "5": {
        "label": "Stato preventivo in attesa documentazione cod",
        "description": "Stato preventivo in attesa documentazione cod"
    },
    "6": {
        "label": "Stato preventivo in attesa di verifica cod",
        "description": "Stato preventivo in attesa di verifica cod"
    },
    "7": {
        "label": "Stato in attesa di emissione cod",
        "description": "Stato in attesa di emissione cod"
    },
    "8": {
        "label": "Stato richiesta emissione cod",
        "description": "Stato richiesta emissione cod"
    },
    "9": {
        "label": "Stato in attesa di firma cod",
        "description": "Stato in attesa di firma cod"
    },
    "10": {
        "label": "Stato conclusa con successo cod",
        "description": "Stato conclusa con successo cod"
    },
    "11": {
        "label": "Stato conclusa con errore cod",
        "description": "Stato conclusa con errore cod"
    },
    "13": {
        "label": "Stato bozza rinnovo id",
        "description": "Stato bozza rinnovo id"
    }
}
}
}
        export const QuotazioneStatoMixin = {
            data() {
                return {
                    QuotazioneStato: new QuotazioneStato(), // Istanza della classe
                };
            },
        };
        