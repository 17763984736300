<template>
    <v-row>
        <v-col cols="8" id="form-veicolo">
            <!-- {{errors}} -->
            <!-- {{errors?.verti_response?.concat()}} -->
            <v-alert
                text
                type="error"
                v-if="errors?.verti_response || !this.data_effetto"
            >
                {{ this.errors?.verti_response }}
            </v-alert>
            <v-alert text type="success" v-if="this.message">
                Esclusione avvenuta con successo
            </v-alert>
            <v-row id="data-decorrenza-quotazione-spese-competenze">
                <v-col id="data-decorrenza">
                    <date-picker
                        label="Data di decorrenza esclusione"
                        v-model="data_effetto"
                        value-type="YYYY-MM-DD"
                        format="DD/MM/YYYY"
                    >
                    </date-picker>
                    <div class="text-danger" v-if="!data_effetto">
                        data di decorrenza obbligatoria
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :items="quotazione.veicoli_storici"
                        :headers="headers_storici"
                    >
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                v-if="item.operazione != 'esclusione'"
                                x-small
                                color="primary"
                                @click="calcoloEsclusione(item)"
                            >
                                Calcola Esclusione
                            </v-btn>
                            <div v-if="item.operazione == 'esclusione'">
                                Veicolo già escluso
                            </div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="4">
            <v-card elevation="0">
                <v-row align="center" class="px-3 pt-0 pb-3">
                    <div class="text-h2">
                        <div v-if="roleIs('super_admin')">
                            € {{ importo }}
                            <v-text-field
                                label="Forza Importo"
                                type="number"
                                v-model="importo"
                            >
                            </v-text-field>
                        </div>
                        <div v-else>€ {{ importo }}</div>
                    </div>
                </v-row>
                <v-card-title class="py-0">Esclusione Veicolo</v-card-title>
                <v-card-text>
                    <!-- Esclusione del veicolo <br> -->
                    <strong>marca</strong>
                    {{ appendiceDiEsclusioneRequest.marca }}<br />
                    <strong>modello</strong>
                    {{ appendiceDiEsclusioneRequest.modello }}<br />
                    <strong>targa</strong>
                    {{ appendiceDiEsclusioneRequest.targa }} <br />
                    <strong>dalla data</strong>
                    {{ appendiceDiEsclusioneRequest.data_effetto }}
                    <br />
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        :disabled="enableEmettiBtn"
                        
                        class="ma-2"
                        color="secondary"
                        @click.stop="previewAppendiceEsclusione"
                    >
                        Preview Appendice di Esclusione
                    </v-btn>

                    <v-navigation-drawer
                        v-model="rightSideDrawer"
                        fixed
                        hide-overlay
                        right
                        temporary
                        :style="{ height: '100vh', width: '33%' }"
                    >
                        <v-divider></v-divider>

                        <v-list dense>
                            <pre if="payloadPreview.data">
                        {{ payloadPreview.data }}

                    </pre
                            >
                        </v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-dialog
                                    data-cy="dialogEmetti"
                                    v-model="dialogEmetti"
                                    max-width="600px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            data-cy="btn_conferma_quotazione_open_dialogEmetti"
                                            if="showEmettiBtn"
                                            small
                                            :disabled="enableEmettiBtn"
                                            color="primary"
                                            dark
                                            class="mb-2"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            Emetti Esclusione
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title class="text-h5"
                                            >Sicuro di voler emettere
                                            l'esclusione
                                        </v-card-title>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                small
                                                color="blue darken-1"
                                                text
                                                >Cancel</v-btn
                                            >
                                            <v-btn
                                                small
                                                color="red"
                                                class="elevation-0"
                                                text
                                                @click="
                                                    emissioneAppendiceEsclusione
                                                "
                                                >OK</v-btn
                                            >
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-list-item-content>
                        </v-list-item>

                        <!-- <v-list-item> -->

                        <v-list-item>
                            <v-list-item-content>
                                <v-btn
                                    class="ma-2"
                                    color="secondary"
                                    @click.stop="
                                        rightSideDrawer = !rightSideDrawer
                                    "
                                >
                                    Chiudi
                                </v-btn>
                            </v-list-item-content>
                        </v-list-item>
                    </v-navigation-drawer>
                </v-card-actions>
                <pre>{{ appendiceDiEsclusioneRequest }}</pre>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import DatePicker from "../ui/form/teo-date-picker.vue";
import { Api } from "@/http_tools/axiosModule";
import {
    HasPermission,
    // HasPermission,
    PuoEmettereAppendiceAutoStorica,
} from "@/services/PermessiRuoliUtente.js";

export default {
    name: "appendice-inclusione-form",
    components: {
        // "veicolo-storico-select": VeicoloStoricoSelect,
        // "form-emissione-veicolo": FormEmissioneVeicolo,
        "date-picker": DatePicker,
    },
    props: ["quotazione"],
    data() {
        return {
            rightSideDrawer: false,
            payloadPreview: {},
            dialogEmetti: false,

            debug: false,
            errors: null,
            response: {},
            loading: false,
            message: "",
            targa: "",
            importo: "",
            data_emissione: "",
            data_effetto: null,
        };
    },
    computed: {
        appendiceDiEsclusioneRequest() {
            return {
                marca: this.marca,
                modello: this.modello,
                targa: this.targa,
                quotazione_id: this.quotazione.id,
                operazione: "esclusione",
                data_effetto: this.data_effetto,
                importo: this.importo,
            };
        },
        showEmettiBtn() {
            return PuoEmettereAppendiceAutoStorica(this.quotazione) && HasPermission('emettere_polizza_auto_storica');
        },
        enableEmettiBtn() {
            return !(
                this.data_effetto &&
                this.importo &&
                PuoEmettereAppendiceAutoStorica(this.quotazione)
            );
        },
        headers_storici() {
            return [
                {
                    text: "Marca",
                    value: "marca",
                },
                {
                    text: "Modello",
                    value: "modello",
                },

                {
                    text: "Anno",
                    value: "anno",
                },

                {
                    text: "Targa",
                    value: "targa",
                },
                {
                    text: "Action",
                    value: "actions",
                },
            ];
        },
    },
    methods: {
        roleIs(role) {
            //console.log(JSON.parse(window.sessionStorage["roles"]).includes(role));
            return JSON.parse(window.sessionStorage["roles"]).includes(role);
        },
        async previewAppendiceEsclusione() {
            try {
                // const payload = JSON.stringify(this.appendiceDiEsclusioneRequest);
                const response = await Api.post(
                    process.env.VUE_APP_API_URL +
                        `/api/preview_appendice_esclusione/${this.appendiceDiEsclusioneRequest.quotazione_id}/${this.appendiceDiEsclusioneRequest.targa}`,
                    this.appendiceDiEsclusioneRequest,
                );
                this.payloadPreview = response;
                this.rightSideDrawer = !this.rightSideDrawer;
                // console.log(response);
            } catch (error) {
                // Gestisci gli errori qui
                console.error(error);
            }
        },
        async emissioneAppendiceEsclusione() {
            // this.ottieniDataSQL();
            // ${this.appendiceDiEsclusioneRequest.quotazione_id}/${this.appendiceDiEsclusioneRequest.targa}
            this.loading = true;
            this.errors = {};
            await Api.post(
                process.env.VUE_APP_API_URL +
                    `/api/emissione_appendice_esclusione/${this.appendiceDiEsclusioneRequest.quotazione_id}/${this.appendiceDiEsclusioneRequest.targa}`,
                this.appendiceDiEsclusioneRequest,
            )
                .then((response) => {
                    this.response = response;
                    this.loading = false;
                    this.dialogEmetti = false;
                    this.message = response.data.message;
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error.response.data);
                        this.loading = false;
                        this.errors = error.response.data.errors;
                        this.dialogEmetti = false;
                        this.message = "";
                    }
                });
        },
        async calcoloEsclusione(item) {
            console.log("calcoloEsclusione", item);
            this.targa = item.targa;
            this.modello = item.modello;
            this.marca = item.marca;
            this.verti_policy_number = item.verti_policy_number;

            if (this.data_effetto != null) {
                this.errors = "";
                this.loading = true;
                const payload = {
                    date: this.data_effetto,
                    vehicle: item,
                };
                await Api.post(
                    process.env.VUE_APP_API_URL +
                        "/api/v1/goold/quotations/exclusion/calculate/" +
                        this.quotazione.id,
                    payload,
                )
                    .then((response) => {
                        this.importo = response.data.data.premio;
                        this.loading = false;
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.loading = false;
                            this.errors = error.response.data.errors;
                        }
                    });
            } else {
                this.errors = "Scegli la data di decorrenza";
            }
        },
    },
};
</script>

<style scoped></style>
