    export default class EnumBase {
        /**
         * Restituisce la label per la chiave fornita.
         * @param {string|number} enumProp - La chiave dell'enum.
         * @returns {string|null} - La label corrispondente o null se non esiste.
         */
        label(enumProp) {
            const entry = this.data[enumProp];
            return entry ? entry.label : null;
        }

        /**
         * Restituisce la descrizione per la chiave fornita.
         * @param {string|number} enumProp - La chiave dell'enum.
         * @returns {string|null} - La descrizione corrispondente o null se non esiste.
         */
        description(enumProp) {
            const entry = this.data[enumProp];
            return entry ? entry.description : null;
        }
    }