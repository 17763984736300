

// StyleMixing.js
import _ from "lodash";


export const cardStyle = {
    class: "pa-0",
};

export const cardStyleRow = {};
export const style_container_padding = {
    class: "pa-5",
};

export const cardStyleCol = {};

export const btn_base_style = {
    outlined: true,
};

export const btn_primary_style = {
    ...btn_base_style,
    color: "primary",
};

export const btn_error_style = {
    ...btn_base_style,
    color: "error",
};

// ---------------------------------
// ALERT
// ---------------------------------

export const alert_base_style = {
    dense: true,
    // icon: "mdi-account",
    outlined: true,
    text: true,
};

export const alert_error_style = {
    ...alert_base_style,
    type: "error",
    // color: "error"
};

export const alert_success_style = {
    ...alert_base_style,
    type: "success",
    outlined: false,
    text: true,
    // color: "error"
};
export const alert_warning_style = {
    ...alert_base_style,
    type: "warning",
    outlined: false,
    text: true,
    // color: "error"
};
export const alert_info_style = {
    ...alert_base_style,
    type: "info",
    outlined: false,
    text: true,
    // color: "error"
};

export const table_selectable_style = {
    dense: true,
    showSelect: true,
    singleSelect: true,
    itemKey: "id",
    color: "primary",
};

export const table_pagination_global_config = {
    dense: true,
    showSelect: true,
    singleSelect: true,
    itemKey: "id",
    color: "primary",
};

export const form_base_style = {
    persistentPlaceholder: true,
    outlined: false,
};



export const StylesMixin = {
    data() {
        return {
            cardStyle: cardStyle,
            cardStyleRow: cardStyleRow,
            cardStyleCol: cardStyleCol,

            btn_primary_style: btn_primary_style,
            btn_error_style: btn_error_style,

            // ALERT
            alert_base_style: alert_base_style,
            alert_error_style: alert_error_style,
            alert_success_style: alert_success_style,
            alert_info_style: alert_info_style,
            alert_warning_style: alert_warning_style,

            table_selectable_style: table_selectable_style,

            form_base_style: form_base_style,
            BtnMainSideBar: {
                small: false,
                dark: true,
                color: "primary",
                outlined: false,
                elevation: 0,
                rounded: false,
                block: true,
                style: {
                    "margin-bottom": "0.5rem",
                },
            },
            BtnModalDialog: {
                small: false,
                dark: true,
                color: "primary",
                outlined: false,
                elevation: 0,
                rounded: false,
                // style:{
                //     width:"auto",

                // }
            },
            BtnSmall: {
                small: true,
                dark: true,
                color: "primary",
                outlined: false,
                elevation: 0,
                rounded: false,
                variant: "plain",
                // style:{
                //     width:"8em",
                // }
            },
        };
    },
    methods: {
        /**
         * Determina il colore in base alla condizione di `item.json`.
         * @param {Object} item - L'oggetto da controllare.
         * @param {String} trueColor - Colore da usare se `item.json` è valorizzato (default: 'null').
         * @param {String} falseColor - Colore da usare se `item.json` è nullo (default: 'red').
         * @returns {String} - Il colore calcolato.
         */
        getIconColor(value, trueColor = 'null', falseColor = 'red') {
            return _.isNil(value) ? falseColor : trueColor;
        },
    }
};



