<template>
    <div data-test="VertiAniaTabs">
   
                <v-tabs v-model="tab">
                    <!-- <v-tab :color="datiAnia.result == 2 ? 'red':null">Dati Ania</v-tab> -->
                    <v-tab :color="datiAnia.result == 2 ? 'red' : undefined">
                        <v-icon v-if="datiAnia.result == 2" :color="datiAnia.result == 2 ? 'red' : undefined">mdi-alert-circle</v-icon>
                        <span :class="datiAnia.result == 2 ? 'red--text' : ''">Dati Ania</span>
                    </v-tab>
                    <v-tab>Avanzato</v-tab>
                </v-tabs>
              
                <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card>
                     
                        <v-card-text v-if="datiAnia.result == 2">
                            <v-chip color="red" text-color="white" style="border-radius: 2px; padding: 5px; margin-right: 5px">Errore Verti</v-chip>
                            <strong>{{ datiAnia.Error[0].descError }}</strong>
                        </v-card-text>
                        <v-card-text v-if="datiAnia">
                           <!-- TODO: Aggiungere i dati Quotazione per confrontare direttamente -->
                            <ResponseATRCDetailTable datiVeicolo="datiVeicolo" :dati-ania="datiAnia"></ResponseATRCDetailTable>
                        </v-card-text>
                
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                       
                        <v-card-text>
                           
                            <pre style="font-size:16px">{{datiAnia}}</pre>
                        
                            <!-- Aggiungi qui ulteriori dettagli o componenti relativi alle opzioni avanzate -->
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                </v-tabs-items>

           
                <!-- <pre>{{datiAnia.ResponseAtrcMain[0].ResponseATRCDetail}}</pre> -->
                <pre>{{datiAnia}}</pre>

    </div>
</template>

<script>
import { StylesMixin } from "@/style/StyleMixing";
import { NumberUtilsMixing } from "@/mixin/NumberUtilsMixing";
import { QuotazioneUtilsMixing } from "@/mixin/QuotazioneUtilsMixing";



import ResponseATRCDetailTable from "./ResponseATRCDetailTable.vue";
import { JsonToolsMixing } from "@/services/JSONTools";
// # Da usare per gli schemi da utilizzare
// import {MainSelectionFormSchema} from "@/v-form-base/form-common"

export default {
    name: "VertiAniaTabs",
    mixins: [NumberUtilsMixing, QuotazioneUtilsMixing, StylesMixin,JsonToolsMixing],
    props: {
        datiAnia: {
            type: Object,
            // required: true
        },
        datiVeicolo: {
            type: Object,
            // required: true
        }
    },
    components: {
        ResponseATRCDetailTable
    },
    created() {},
    async mounted() {},
    data() {
        return {
            tab:0
        };
    },
   
    methods: {},
    computed: {},
};


</script>

<style></style>
