<template>
    <div data-test="QuotazioneRCASearch">
        <!-- Aggiungiamo il pulsante Nuova Quotazione -->
        <v-row class="mb-4">
            <v-col cols="12" class="d-flex justify-end">
                <v-btn
                    color="primary"
                    @click="addNewQuotazione"
                    data-cy="addNewQuotazione"
                    v-bind="btn_primary_style"
                >
                    <v-icon left>mdi-plus</v-icon>
                    Nuova Quotazione
                </v-btn>
            </v-col>
        </v-row>

        <!-- Tabella dei risultati -->
        <v-data-table
            v-model="selectedItem"
            :headers="headers_datatable"
            :items="items"
            :loading="loading"
            :items-per-page="itemsPerPage"
            :page="page"
            :fixed-header="true"
            :single-select="true"
            :item-class="getRowClass"
            v-bind="table_selectable_style"
            @page="onPageChange"
            @item-selected="onRowClick"
        >
            <template v-slot:[`header.cliente.label`]="{ header }">
                {{ header.text }}
                <v-text-field
                    v-if="ui?.showDataTableFilter"
                    @input="debounceFetchItems"
                    v-model="params.filter.cognome_nome"
                    class="background-primary-light ma-0"
                    clearable
                    persistent-hint
                    dense
                ></v-text-field>
            </template>

            <template v-slot:[`header.collaboratore.label`]="{ header }">
                {{ header.text }}
                <r-autocomplete
                    v-if="ui?.showDataTableFilter"
                    v-model="params.filter.collaboratore_id"
                    source="/api/autocomplete/collaboratori"
                    class="background-primary-light ma-0"
                    clearable
                    dense
                    @input="debounceFetchItems"
                />
            </template>

            <template v-slot:[`header.created_at`]="{ header }">
                {{ header.text }}
                <v-text-field
                    v-if="ui?.showDataTableFilter"
                    @input="debounceFetchItems"
                    v-model="params.filter.created_at"
                    class="background-primary-light ma-0"
                    clearable
                    dense
                ></v-text-field>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at }}
            </template>

            <template v-slot:[`header.validity_date`]="{ header }">
                {{ header.text }}
                <v-text-field
                    v-if="ui?.showDataTableFilter"
                    @input="debounceFetchItems"
                    v-model="params.filter.validity_date"
                    class="background-primary-light ma-0"
                    clearable
                    dense
                ></v-text-field>
            </template>

            <template v-slot:[`header.simulation_number`]="{ header }">
                {{ header.text }}
                <v-text-field
                    v-if="ui?.showDataTableFilter"
                    @input="debounceFetchItems"
                    v-model="params.filter.simulation_number"
                    class="background-primary-light ma-0"
                    clearable
                    dense
                ></v-text-field>
            </template>

            <template v-slot:[`header.simulation_end_date`]="{ header }">
                {{ header.text }}
                <v-text-field
                    v-if="ui?.showDataTableFilter"
                    @input="debounceFetchItems"
                    v-model="params.filter.simulation_end_date"
                    class="background-primary-light ma-0"
                    clearable
                    dense
                ></v-text-field>
            </template>

            <!-- Aggiungiamo la colonna delle azioni -->
            <template v-slot:[`header.actions`]> Azioni </template>

            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex">
                    <v-icon
                        :data-cy="'editItem_' + item.id"
                        small
                        @click="editItem(item)"
                        class="mr-2"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        v-if="canDelete(item)"
                        :data-cy="'deleteItem_' + item.id"
                        small
                        @click="deleteItem(item)"
                        class="mr-0"
                    >
                        mdi-delete
                    </v-icon>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
/* eslint-disable */
import { Api } from "@/http_tools/axiosModule";
import { DateMixin } from "@/services/DateUtils";
import { JsonToolsMixing } from "@/services/JSONTools";
import { StylesMixin } from "@/style/StyleMixing";
import { debounce } from "lodash";
import RAutocomplete from "@/components/common/r-autocomplete.vue";
import { mapState, mapActions } from "vuex";

export default {
    name: "QuotazioneRCASearch",
    components: {
        RAutocomplete,
    },
    mixins: [StylesMixin, JsonToolsMixing, DateMixin],
    created() {
        this.debounceFetchItems = debounce(this.fetchItems, 1000);
        // Inizializza UI Router con i parametri dell'URL corrente

        // TODO: rimettere questa funzione

        // this.syncUiFromUrl({
        //     path: this.$route.path,
        //     query: this.$route.query,
        // });
    },
    async mounted() {
        this.debounceFetchItems();
        // Carica lo stato UI dal localStorage se presente
        // this.loadUiFromStorage();
    },
    data() {
        return {
            ui: {
                showDataTableFilter: false, // Controllo visibilità dei filtri
            },
            headers_datatable: [
                // {
                //     text: "",
                //     value: "data-table-select", // Colonna per la checkbox
                //     width: "auto", // Specifica la larghezza in pixel
                //     align: "start",
                //     class: "text-no-wrap",
                //     cellClass: "px-5 text-no-wrap",
                //     sortable: false,
                //     divider: false,
                // },
                {
                    text: "Targa",
                    value: "plate",
                    width: "7rem",
                    align: "start",
                    class: "text-no-wrap",
                    cellClass: "px-5 text-no-wrap",
                    sortable: false,
                    divider: false,
                },
                {
                    text: "id",
                    value: "id", // Colonna per la checkbox
                    width: "auto", // Specifica la larghezza in pixel
                    align: "start",
                    class: "text-no-wrap",
                    cellClass: "px-5 text-no-wrap text-start",
                    sortable: false,
                    divider: false,
                },
                {
                    text: "Cliente",
                    value: "cliente.label",
                    align: "start",
                    sortable: true,
                    divider: false,
                    class: "text-no-wrap",
                    cellClass: "px-5 text-no-wrap",
                    width: "auto",
                },
                {
                    text: "Collaboratore",
                    value: "collaboratore.label",
                    align: "start",
                    sortable: true,
                    divider: false,
                    class: "text-no-wrap",
                    cellClass: "px-5 text-no-wrap",
                    width: "auto",
                },
                {
                    text: "Data Creazione",
                    value: "created_at",
                    align: "start",
                    sortable: true,
                    divider: false,
                    class: "text-no-wrap",
                    cellClass: "px-5 text-no-wrap",
                    width: "auto",
                },
                {
                    text: "Validità",
                    value: "validity_date",
                    align: "start",
                    sortable: true,
                    divider: false,
                    class: "text-no-wrap",
                    cellClass: "px-5 text-no-wrap",
                    width: "auto",
                },
                {
                    text: "Numero Simulazione",
                    value: "simulation_number",
                    align: "start",
                    sortable: true,
                    divider: false,
                    class: "text-no-wrap",
                    cellClass: "px-5 text-no-wrap",
                    width: "auto",
                },
                {
                    text: "Scadenza",
                    value: "simulation_end_date",
                    align: "start",
                    sortable: true,
                    divider: false,
                    class: "text-no-wrap",
                    cellClass: "text-no-wrap",
                    width: "auto",
                },
                {
                    text: "Azioni",
                    value: "actions",
                    align: "start",
                    sortable: true,
                    divider: false,
                    class: "text-no-wrap",
                    cellClass: "text-no-wrap",
                    width: "auto",
                },
            ],

            items: [],
            loading: false,
            itemsPerPage: 100,
            page: 1,
            params: {
                filter: {},
            },
            selectedItem: [],
        };
    },
    methods: {
        // ...mapActions("UiRouterModule", [
        //     "syncUiFromUrl",
        //     "loadUiFromStorage",
        //     "updateUrlFromUi",
        // ]),

        async fetchItems() {
            this.loading = true;
            try {
                const response = await Api.get(
                    process.env.VUE_APP_API_URL + "/api/quotazioni_rca",
                    {
                        params: this.params,
                    },
                );
                this.items = response.data.data;
            } finally {
                this.loading = false;
            }
        },
        onPageChange(page) {
            this.page = page;
            this.debounceFetchItems();
        },
        onRowClick(rowData) {
            this.$emit("onSearchResultSelected", rowData);
        },
        getRowClass(rowData) {
            rowData;
            return "";
        },
        addNewQuotazione() {
            this.$router.push({ name: "quotazione_rca_store" });
        },
        editItem(item) {
            this.$router.push({
                name: "quotazione_rca_edit",
                params: { id: item.id },
            });
        },
        deleteItem(item) {
            this.itemToDelete = item;
            this.dialogDelete = true;
        },
        async deleteItemConfirm() {
            try {
                await Api.delete(
                    `${process.env.VUE_APP_API_URL}/api/quotazioni_rca/${this.itemToDelete.id}`,
                );

                const index = this.items.findIndex(
                    (i) => i.id === this.itemToDelete.id,
                );
                if (index > -1) {
                    this.items.splice(index, 1);
                }

                this.$emit("flash", {
                    message: "Quotazione eliminata con successo",
                    type: "success",
                });

                this.fetchItems();
            } catch (error) {
                this.$emit("error", error);
            } finally {
                this.closeDelete();
            }
        },
        closeDelete() {
            this.dialogDelete = false;
            this.itemToDelete = null;
        },
        canDelete(item) {
            return true;
            // ! TODO: Implementa la logica di controllo per l'eliminazione
            // In quali stati posso eliminare la quotazione
        },
        // toggleDataTableFilter() {
        //     // Aggiorna lo stato UI e sincronizza con l'URL
        //     this.$store.commit("UiRouterModule/setUi", {
        //         showDataTableFilter: !this.ui.showDataTableFilter,
        //     });
        //     this.updateUrlFromUi();
        // },
    },
    computed: {
        // ...mapState("UiRouterModule", ["ui"]),
    },
    watch: {
        $route(to) {
            this.syncUiFromUrl({
                path: to.path,
                query: to.query,
            });
        },
    },
};
</script>
<!-- <style>
.v-icon.v-data-table-header__icon.mdi-arrow-up {
    position: absolute !important;
    top: 0;
    right: 1rem;
}
.v-input__icon.v-input__icon--clear {
    position: absolute;
    background-color: #cbcbcb;
    z-index: 1;
    right: 0rem;
    /* color: red; */
}

.background-primary-light {
    background-color: var(
        --v-primary-lighten3
    ); /* Puoi cambiare il livello di "lighten" */
}
</style> -->

<style>
td.text-start.text-no-wrap {
    margin: 0 !important;
    padding: 0.6rem !important;
}
</style>
