export const MaritalStatusDtoEnum = [
  {
    label: "Sposato",
    description: "Sposato/a",
    value: "Married"
  },
  {
    label: "Divorziato",
    description: "Divorziato/a",
    value: "Divorced"
  },
  {
    label: "Sconosciuto",
    description: "Sconosciuto/a",
    value: "Unknown"
  },
  {
    label: "Separato",
    description: "Separato/a",
    value: "Separated"
  },
  {
    label: "Single",
    description: "Celibe/celibe",
    value: "Single"
  },
  {
    label: "Fidanzato",
    description: "Fidanzato/a",
    value: "Engaged"
  },
  {
    label: "Vedovo",
    description: "Vedovo/a",
    value: "Widowed"
  },
];
