export default {
    "Owner": "Owner",
    "Tenant": "Tenant",
    "Holder": "Holder",
    "OwnerAndHolder": "OwnerAndHolder",
    "TenantAndHolder": "TenantAndHolder",
    "CounterParty": "CounterParty",
    "OwnerAndCounterParty": "OwnerAndCounterParty"
};

export const PersonTypeDto = {
    "Owner": {
        "label": "Proprietario",
        "description": "Proprietario o avente diritto"
    },
    "Tenant": {
        "label": "Utilizzatore",
        "description": "Utilizzatore del veicolo"
    },
    "Holder": {
        "label": "Contraente",
        "description": "Contraente"
    },
    "OwnerAndHolder": {
        "label": "Proprietario e contraente coincidono",
        "description": "Proprietario e Contaente"
    },
    "TenantAndHolder": {
        "label": "Utilizzatore e contraente coincidono",
        "description": "Utilizzatore e Proprietario"
    },
    "CounterParty": {
        "label": "Societ\u00e0 leasing",
        "description": "societ\u00e0 leasing, coinvolta in un contratto o transazione"
    },
    "OwnerAndCounterParty": {
        "label": "Proprietario e societ\u00e0 leasing",
        "description": "Proprietario e societ\u00e0 leasing"
    }
};