import _ from "lodash";
// __frontend/src/services/JSONTools.js
// DOCS: https://stackoverflow.com/questions/45897879/how-to-set-all-values-of-an-object-to-null-in-javascript
export function setEmpty(input) {
    let keys = Object.keys(input);

    for (let key of keys) {
        if (typeof input[key] != "object") {
            input[key] = null;
        } else {
            setEmpty(input[key]);
        }
    }
    return input;
}

export function castToSiNo(json) {
    return _.mapValues(json, (value) => {
        if (_.isPlainObject(value)) {
            // Se l'elemento è un oggetto, applica ricorsivamente la funzione
            return castToSiNo(value);
        } else if (value === true) {
            return "si";
        } else if (value === false) {
            return "no";
        }
        return value;
    });
}

export function castToBoolean(json) {
    return _.mapValues(json, (value) => {
        if (_.isPlainObject(value)) {
            // Se l'elemento è un oggetto, applica ricorsivamente la funzione
            return castToBoolean(value);
        } else if (value === "si") {
            return true;
        } else if (value === "no") {
            return false;
        }
        return value;
    });
}

/**
 * Esegue map su un array solo se l'array esiste ed è valido
 * @param {Array} arr - Array da mappare
 * @param {Function} fn - Funzione di mapping
 * @param {Array} defaultValue - Valore di default se l'array non è valido
 * @returns {Array} Array mappato o valore di default
 */
export function mapIf(arr, fn, defaultValue = []) {
    try {
        if (arr && Array.isArray(arr)) {
            return arr.map(fn);
        }
        console.warn("mapIf: array non valido", arr);
        return defaultValue;
    } catch (error) {
        console.error("mapIf: errore durante il mapping", error);
        return defaultValue;
    }
}

export const JsonToolsMixing = {
    methods: {
        isEmpty(obj) {
            return _.isEmpty(obj);
        },
        uniqBy(array, key) {
            return _.uniqBy(array, key);
        },
        orderBy(array, keysToQrder, orderStrategy) {
            return _.orderBy(array, keysToQrder, orderStrategy);
        },
        // Aggiungiamo mapIf anche al mixing
        mapIf(arr, fn, defaultValue = []) {
            return mapIf(arr, fn, defaultValue);
        },
    },
    filters: {
        pretty(value) {
            return JSON.stringify(JSON.parse(value), null, 2);
        },
    }
};

/**
//! transformErrors
Permette di formattare la risposta per errori di laravel complessi

@example
```js
const errors = {
    "simulationObject.youngestDriverAge": [
        "L'età del conducente più giovane è richiesta."
    ],
    "simulationObject.isDrivenByYoungers": [
        "campo obbligatorio"
    ]
};

const transformedErrors = transformErrors(errors);
console.log(transformedErrors);
```
*/
export function transformErrors(errors) {
    const formattedErrors = {};

    for (const key in errors) {
        const keys = key.split("."); // Divide la chiave in base ai punti
        let current = formattedErrors; // Inizializza il puntatore

        keys.forEach((subKey, index) => {
            // Se la chiave non esiste ancora, la inizializziamo
            if (!current[subKey]) {
                current[subKey] = index === keys.length - 1 ? errors[key] : {}; // Assegna i messaggi solo all'ultima chiave
            }
            current = current[subKey]; // Sposta il puntatore all'oggetto corrente
        });
    }

    return formattedErrors;
}

/**
 *
 * @param {chiamare una proprietà value nello schema provocava errori} jsonData
 * @returns
 */
export function replaceValueContact(jsonData) {
    jsonData.contacts?.forEach((contact) => {
        if (contact.value_contact) {
            contact.value = contact.value_contact;
            delete contact.value_contact;
        }
    });
    return jsonData;
}


